import React from "react";
import styled, { keyframes } from "styled-components";

import AniLink from "gatsby-plugin-transition-link/AniLink";

import Arrow from "../../images/Arrow.svg";
import syningarvefurImg from '../../images/screen.jpg'
import { mediaMax, mediaMin } from '../../utils/breakpoints'
import { MyLocationEnum } from "../Layout";

export const fadeInUp = () => keyframes`
	0% {
    transform: translateY(-60px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

interface MenuProps {
  myLocation?: MyLocationEnum;
  onSameSiteClick?: () => void;
}
export const Menu = ({ myLocation, onSameSiteClick }: MenuProps) => {
  return (
    <MenuList>
      {myLocation === MyLocationEnum.Live ? (
        <MenuItem onClick={onSameSiteClick}>Bein útsending</MenuItem>
      ) : (
        <MenuItem as={AniLink} cover to={MyLocationEnum.Live}>
          Bein útsending
        </MenuItem>
      )}

      {/* <MenuItem as={AniLink} cover to={MyLocationEnum.Framundan}>
        Framundan
      </MenuItem> */}

      {/* <QuickFixMenu as="a" href="https://haustradstefna.advania.is/">
        Sýningarvefur

        <div className="image-container">
          <img src={syningarvefurImg} />
        </div>
      </QuickFixMenu> */}
    </MenuList>
  );
};

const MenuList = styled.div`
  display: flex;
  flex-direction: column;

  animation: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s 1 normal forwards
    running ${fadeInUp};
`;

const QuickFixMenu = styled.div`
  color: #ffffff;
  font-family: "Adelle Sans";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  text-decoration: none;
  position: relative;

  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;

  a {
    color: #fff;
    text-decoration: none;
  }


  &:focus {
    outline: none;
  }


  &.disabled {
    pointer-events: none;
    text-decoration: line-through;
  }

  .image-container {
    height: 150px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background: #0e1e24;
      transition: opacity .15s ease;
      opacity: 0.2;
      left: 0;
      top: 0;
    }

    > img {
      height: 100%;
      width: auto;
      border-radius: 6px;
      border: 6px solid white;
    }
  }

  &:hover .image-container:after {
    opacity: 0;
  }

  @media ${mediaMax.tabletL} {
    .image-container {
      display: none;
    }

    &:after {
      content: "";
      height: 40px;
      width: 25px;
      background-image: url(${Arrow});
      position: absolute;
      right: 0;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }
`

const MenuItem = styled.button`
  /* ${({}) => theme.co} */
  color: #ffffff;
  font-family: "Adelle Sans";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  text-decoration: none;
  position: relative;

  border: none;
  background: transparent;
  padding: 0;
  text-align: left;
  cursor: pointer;

  a {
    color: #fff;
    text-decoration: none;
    
  }


  &:focus {
    outline: none;
  }

  &:after {
    content: "";
    height: 40px;
    width: 25px;
    background-image: url(${Arrow});
    position: absolute;
    right: 0;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  &.disabled {
    pointer-events: none;
    text-decoration: line-through;
  }
`;
