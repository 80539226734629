import firebase from 'firebase/app';
import 'firebase/auth'; // importing the auth module as an example
import 'firebase/database'; // importing the auth module as an example

const config = {
  apiKey: "AIzaSyBg2d9S3KaThKnJy7YdSkQ9rx9AQrntBZw",
  authDomain: "demoradstefna.firebaseapp.com",
  databaseURL: "https://demoradstefna.firebaseio.com",
  projectId: "demoradstefna",
  storageBucket: "demoradstefna.appspot.com",
  messagingSenderId: "524095712118",
  appId: "1:524095712118:web:7dc9376ee1da7919b12857"
}

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}