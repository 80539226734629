export enum MyLocationEnum {
  Lobby = "/lobby",
  Syningabasar = "/syningarymi",
  Skraning = "/skraning",
  Live = "/beint",
  Dagskra = "/dagskra",
  Speaker = "/fyrirlesari",
  _404LostAndAlone = "/404",
  Bransasogur = "/naermynd",
  Framundan = "/framundan",
  Records = "/upptokur",
  Hlidardagskra = "/hlidardagskra",
  Dell = "/dell",
  NCR = "/ncr",
  BoostAI = "/boost-ai",
  Outsystems = "/outsystems",
  Forcepoint = "/forcepoint",
  Oracle = "/oracle",
  Salesforce = "/salesforce"
}
