
import { Inputs } from '../../../../../types/inputs'


export const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/*
 * We need some basic validation logic so we write our own ofc.
 */

interface Errors {
	[key: string]: string
}

interface Validation {
	errors: Errors,
	isValid: boolean
}
export default (inputs: any):Validation => {
	let errors: any = {}
	const { name, company, email } = inputs

	// Name validation.
	if(!name.value) {
		errors["name"] = "*Vantar að fylla inn nafn"
	}

	// Company validation.
	if(!company.value) {
		errors["company"] = "*Vantar að fylla inn nafn fyrirtækis"
	}

	// Email validation
	if(!email.value) {
		errors["email"] = "*Vantar að fylla inn netfang"
	}
	else if(!regex_email.test(String(email.value).toLowerCase())) {
		errors["email"] = "*Ógilt netfang"
	}

	const validation: Validation = {
		errors,
		isValid: Object.keys(errors).length === 0
	}
	
	return  validation
}