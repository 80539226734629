
import { createGlobalStyle } from "styled-components";


import adelleLight from '../fonts/Adelle/adelle_light-webfont-woff.woff'
import adelleLightItalic from '../fonts/Adelle/adelle_lightitalic-webfont-woff.woff'
import adelleRegular from '../fonts/Adelle/adelle_regular-webfont-woff.woff'
import adelleItalic from '../fonts/Adelle/adelle_italic-webfont-woff.woff'
import adelleSemiBold from '../fonts/Adelle/adelle_semibold-webfont-woff.woff'
import adelleSemiBoldItalic from '../fonts/Adelle/adelle_semibolditalic-webfont-woff.woff'
import adelleBold from '../fonts/Adelle/adelle_bold-webfont-woff.woff'
import adelleBoldItalic from '../fonts/Adelle/adelle_bolditalic-webfont-woff.woff'

import adelleSansRegular from '../fonts/AdelleSans/AdelleSans-Regular.woff'
import adelleSansLight from '../fonts/AdelleSans/AdelleSans-Light.woff'
import adelleSansBold from '../fonts/AdelleSans/AdelleSans-Bold.woff'


const GlobalStyle = createGlobalStyle`
  body,
	html {
		min-width: 320px;
		min-height: 100%;
	}

	html {
		// background: #fff;
		min-height: 100%;
		width: 100vw;
		overflow-x: hidden;
		margin: 0 auto;
	}

	/* Set core body defaults */
	body {
		padding: 0;
		line-height: 1.5;
		-webkit-font-smoothing: antialiased;
		// text-rendering: optimizeSpeed;
		text-rendering: optimizeLegibility;
		// Nice to know about. Un-comment by need.
		// scroll-behavior: smooth;
	}

	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border: 0;
	}

		/* Box sizing rules */
	*,
	*::before,
	*::after {
		box-sizing: border-box;
	}

	/* Remove default padding */
	ul[class],
	ol[class] {
		padding: 0;
	}

	/* Remove default margin */
	body,
	h1,
	h2,
	h3,
	h4,
	p,
	ul[class],
	ol[class],
	li,
	figure,
	figcaption,
	blockquote,
	dl,
	dd {
		margin: 0;
	}

	/* Remove list styles on ul, ol elements with a class attribute */
	ul[class],
	ol[class] {
		list-style: none;
	}

	/* A elements that don't have a class get default styles */
	a:not([class]) {
		text-decoration-skip-ink: auto;
	}

	/* Make images easier to work with */
	/* img {
		max-width: 100%;
		display: block;
	} */

	@media (prefers-reduced-motion: reduce) {
		* {
			animation-duration: 0.01ms !important;
			animation-iteration-count: 1 !important;
			transition-duration: 0.01ms !important;
			scroll-behavior: auto !important;
		}
	}

	/*
	 *  TODO: Lower the font size
	 * Are we supporting IE ? No alright take out a lot of stuff.
	 */
	/* Thin */ 
	@font-face {
		font-family: "Adelle";
		src: url(${adelleLight});
		font-weight: 300;
		font-style: normal;
		font-display: swap;
	}

	/* Thin Italics */
	@font-face {
		font-family: "Adelle";
		src: url(${adelleLightItalic});
		font-weight: 300;
		font-style: italic;
		font-display:swap
	}

	/* Regular */
	@font-face {
		font-family: "Adelle";
		src: url(${adelleRegular});
		font-weight: normal;
		font-style: normal;
		font-display:swap
	}

	/* Regular Italics */
	@font-face {
		font-family: "Adelle";
		src: url(${adelleItalic});
		font-weight: normal;
		font-style: italic;
		font-display:swap
	}

	/* Medium */
	@font-face {
		font-family: "Adelle";
		src: url(${adelleSemiBold});
		font-weight: 600;
		font-style: normal;
		font-display:swap
	}

	/* Medium Italics */
	@font-face {
		font-family: "Adelle";
		src: url(${adelleSemiBoldItalic});
		font-weight: 600;
		font-style: italic;
		font-display:swap
	}

	/* Bold */
	@font-face {
		font-family: "Adelle";
		src: url(${adelleBold});
		font-weight: 700;
		font-style: normal;
		font-display:swap
	}

	/* Bold Italics */
	@font-face {
		font-family: "Adelle";
		src: url(${adelleBoldItalic});
		font-weight: 700;
		font-style: italic;
		font-display:swap
	}

	@font-face {
		font-family: 'Adelle Sans';
		src: url(${adelleSansRegular});
		font-weight: normal;
		font-style: normal;
		font-display:swap
	}

	@font-face {
		font-family: 'Adelle Sans';
		src: url(${adelleSansLight});
		font-weight: 300;
		font-style: normal;
		font-display:swap
	}

	@font-face {
		font-family: 'Adelle Sans';
		src: url(${adelleSansBold});
		font-weight: bold;
		font-style: normal;
		font-display:swap
	}
`

export { GlobalStyle };