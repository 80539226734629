interface Color {
	base: string,
	light: string,
	dark: string,
}

export interface Theme {
	colors: {
		black: string,
		white: string,
		warning: string,
		success: string,
		primary: Color,
		secondary: Color,
		third: Color,
	},
	fonts: {
		// TODO: We need to add some fallback fonts.
		fontFamilyPrimary: string,
		fontFamilySecondary: string,
	}
}

export default {
  colors: {
		black: `#303030`,
		white: `#fff`,
		warning: `#F03434`,

		// TODO: Find a better color.
		success: `green`,
		
		// Original orange.
		primary: {
			base: `#F38901`,
			light: `#f3dc30`,
			dark: `#ce5120`,
		},
		// Original blue.
		secondary: {
			base: `#2ABAD9`,
			light: `#92CCE2`,
			dark: `#318EAA`,
		},
		// Original purple.
		third: {
			base: `#971f82`,
			light: `#ad0081`,
			dark: `#4f0077`
		}
	},
	fonts: {
		// TODO: We need to add some fallback fonts.
		fontFamilyPrimary: "Adelle",
		fontFamilySecondary: "Adelle Sans",
	}
};