import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";

import LogoHere from "../../images/logohere.png";
import advaniaLogo from "../../images/advaniaLogo.png";
import { Menu, fadeInUp } from "../Menu/Menu";
import { MenuSeparator } from "../Menu/MenuSeparator";
// import BackgroundVideo from "../../images/EsjanLoopa.mp4";
import BackgroundVideo from "../../images/Blue Glitter Explosion HD 1.mp4";
import loadingGif from "../../images/spinner.gif";
import useFirebase from "../../../useFirebase";
import registerForm from "../../utils/netlifyForms/registerForm";
import validate, {
  regex_email,
} from "../Register/components/ConferenceCard/utils/validation";
import { MyLocationEnum } from "../Layout";
import { number_size, mediaMax } from "../../utils/breakpoints";

export const fadeOut = () => keyframes`
	0% {
	  opacity: 1;
	}
  
	100% {
	  opacity: 0;
	}

`;

interface firebaseItem {
  email?: string;
}

interface LobbyProps {
  show: boolean;
  myLocation?: MyLocationEnum;
  onCloseLobby: () => void;
  zIndex?: number | string;
}

export default ({ show, myLocation, onCloseLobby }: LobbyProps) => {
  const firebase = useFirebase();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [userEmail, setUserEmail] = useState("");
  const [loginVisible, setLoginVisible] = useState(true);
  const [registerVisible, setRegisterVisible] = useState(false);
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [registerWorkplace, setRegisterWorkplace] = useState("");
  const [RegisterErrors, setRegisterErrors] = useState([]);
  const [LoginErrors, setLoginErrors] = useState("");
  const [superError, setSuperError] = useState("");
  const [spinnerActive, setSpinnerActive] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const SubmitRegister = () => {
    setSpinnerActive(true);

    const inputs = {
      email: { value: registerEmail },
      name: { value: registerName },
      company: { value: registerWorkplace },
    };

    const validation = validate(inputs);
    setIsValid(validation.isValid);

    let tmpErrors = [];
    if (validation.errors["email"] != undefined) {
      tmpErrors.push(validation.errors["email"]);
    }
    if (validation.errors["name"] != undefined) {
      tmpErrors.push(validation.errors["name"]);
    }
    if (validation.errors["company"] != undefined) {
      tmpErrors.push(validation.errors["company"]);
    }

    setRegisterErrors(tmpErrors);

    if (validation.isValid) {
      registerForm(inputs)
        .then(() => {
          setLoginVisible(false);
          setRegisterVisible(false);

          try {
            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({
              event: "buttonClick",
              category: "haustradstefna",
              action: "innskraning",
              label: "success",
            });
          } catch {}

          setTimeout(() => {
            const item: firebaseItem = {
              email: registerEmail,
            };

            firebase.database().ref("users").push(item);

            if (typeof window !== undefined) {
              localStorage.setItem("loggedIn", registerEmail);
              setIsLoggedIn(true);
              setSpinnerActive(false);
              return true;
            }
          }, 1000);
        })
        .catch((error) => {
          setSuperError("Náði ekki að senda umsókn");
          setSpinnerActive(false);
        });
    } else {
      setSpinnerActive(false);
    }
  };

  const getUser = (userEmail: string) => {
    setSpinnerActive(true);

    if (!regex_email.test(String(userEmail).toLowerCase())) {
      setLoginErrors("*Ógilt netfang");
      setSpinnerActive(false);
    }
    // TODO: setTimeout not clear... resulting in empty page with nothing.
    // Breaks layout
    else if (firebase != null) {
      firebase
        .database()
        .ref("/")
        .child("users")
        .orderByChild("email")
        .equalTo(userEmail)
        .on("value", function (snapshot) {
          if (snapshot.val() != null) {
            snapshot.forEach(function (data) {
              if (data.val().email != "" && typeof window !== undefined) {
                setLoginVisible(false);
                setRegisterVisible(false);

                setTimeout(() => {
                  localStorage.setItem("loggedIn", data.val().email);
                  setIsLoggedIn(true);
                  setSpinnerActive(false);
                }, 1000);
              } else {
                setLoginErrors("Notandi finnst ekki");
                setSpinnerActive(false);
              }
            });
          } else {
            setLoginErrors("Notandi finnst ekki");
            setSpinnerActive(false);
          }
        });
    }
  };

  // Playing the video when not needed takes up resources.
  const videoRef = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (typeof window === undefined || videoRef.current === null) {
      return;
    }

    try {
      if (!show) {
        const p = videoRef.current.pause();
        // p.catch(() => {})
      } else {
        const p = videoRef.current.play();
        p.catch(() => {
          // console.log("");
        });
      }
    } catch {}
  }, [show]);

  if (isLoggedIn == null) {
    return <div></div>;
  } else {
    return (
      <Background className={show ? "show" : ""}>
        <StyledVideo autoPlay muted loop ref={videoRef}>
          <source src={BackgroundVideo} type="video/mp4" />
        </StyledVideo>

        {/* <StyledVideo>
					<iframe src="https://player.vimeo.com/video/455521982?controls=0&autoplay=1&loop=true" height="100%" width="100%"/>
				</StyledVideo> */}

        <Content>
          <StyledIcon>
            <h1>Fagnaður - Fjör - Fjarlægð</h1>
          </StyledIcon>
          {isLoggedIn ? (
            <SeparatorContainer>
              <MenuSeparator
                title="VELKOMIN"
                width="48%"
                showColor={true}
                lineColor="#F38901"
                showInMobile={false}
                isButton={false}
              >
                <MenuText>
                Velkomin í fjartý Sérlausna, reimið á ykkur inniskóna, opnið búbblurnar, hallið ykkur aftur og njótið, þið eigið það skilið! 
               </MenuText>
              </MenuSeparator>

              <MenuSeparator
                title="VALMYND"
                width="48%"
                showColor={true}
                lineColor="#2ABAD9"
                showInMobile={true}
                isButton={false}
              >
                <Menu myLocation={myLocation} onSameSiteClick={onCloseLobby} />
              </MenuSeparator>
            </SeparatorContainer>
          ) : (
            <SeparatorContainer>
              <MenuSeparator
                title="VELKOMIN"
                width="57.6%"
                margin="10.4%"
                showColor={true}
                lineColor="#F38901"
                showInMobile={false}
                isButton={false}
              >
                <MenuText>
                  Hérna kemur texti sem birtist áður en notandi hefur skráð sig
                  inn eða nýskráð sig.
                </MenuText>
              </MenuSeparator>

              <MenuSeparator
                title="INNSKRÁ"
                width="14.8%"
                margin="2%"
                showColor={loginVisible ? true : false}
                lineColor="#2ABAD9"
                isButton={true}
                showInMobile={true}
                handleClick={() => {
                  setLoginVisible(true);
                  setRegisterVisible(false);
                }}
              ></MenuSeparator>
              <MenuSeparator
                title="NÝSKRÁ"
                width="14.8%"
                lineColor="#2ABAD9"
                showColor={registerVisible ? true : false}
                isButton={true}
                showInMobile={true}
                handleClick={() => {
                  setLoginVisible(false);
                  setRegisterVisible(true);
                }}
              ></MenuSeparator>

              <FormContent
                className={loginVisible ? "active" : ""}
                width="31.6%"
                onSubmit={(e: any) => {
                  e.preventDefault();
                }}
              >
                {" "}
                {/* Width here has to be the combined percentage of the above tabs with margin if here is any */}
                {superError != "" && (
                  <StyledErrorContainer>
                    <div className="message">{superError}</div>
                  </StyledErrorContainer>
                )}
                <StyledInput
                  type="email"
                  placeholder="Netfang"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUserEmail(e.target.value)
                  }
                />
                <StyledButton onClick={() => getUser(userEmail)}>
                  <StyledSpinner
                    className={spinnerActive ? "active" : ""}
                    src={loadingGif}
                    alt="spinner"
                    aria-hidden="true"
                  />
                  <ButtonText className={spinnerActive ? "hidden" : ""}>
                    Innskrá
                  </ButtonText>
                </StyledButton>
                {LoginErrors != "" && (
                  <StyledErrorContainer>
                    <div className="message">{LoginErrors}</div>
                  </StyledErrorContainer>
                )}
              </FormContent>
              <FormContent
                className={registerVisible ? "active" : ""}
                width="31.6%"
                onSubmit={(e: any) => {
                  e.preventDefault();
                }}
              >
                {" "}
                {/* Width here has to be the combined percentage of the above tabs with margin if here is any */}
                <StyledInput
                  type="text"
                  placeholder="Netfang"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRegisterEmail(e.target.value)
                  }
                />
                <StyledInput
                  type="text"
                  placeholder="Nafn"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRegisterName(e.target.value)
                  }
                />
                <StyledInput
                  type="text"
                  placeholder="Vinnustaður"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRegisterWorkplace(e.target.value)
                  }
                />
                <StyledButton onClick={() => SubmitRegister()}>
                  <StyledSpinner
                    className={spinnerActive ? "active" : ""}
                    src={loadingGif}
                    alt="spinner"
                    aria-hidden="true"
                  />
                  <ButtonText className={spinnerActive ? "hidden" : ""}>
                    Nýskrá
                  </ButtonText>
                </StyledButton>
                {!isValid && (
                  <StyledErrorContainer>
                    <div className="message">
                      {RegisterErrors.map((error) => (
                        <span>{error}</span>
                      ))}
                    </div>
                  </StyledErrorContainer>
                )}
              </FormContent>
            </SeparatorContainer>
          )}
        </Content>
      </Background>
    );
  }
};

const StyledVideo = styled.video`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  object-fit: cover;
  z-index: 10;
  filter: blur(3px);
`;

const StyledErrorContainer = styled.div`
  display: flex;
  padding: 1em 0 0 1.25em;

  .message {
    font-family: "Adelle Sans";
    display: flex;
    flex-direction: column;
    letter-spacing: 0;
    font-size: 1.2em;
    line-height: 1.43em;
    color: red;
    text-align: right;
    flex: 1;
  }
`;

const StyledSpinner = styled.img`
  display: none;
  height: 40px;
  width: 40px;

  &.active {
    display: block;
  }
`;

const ButtonText = styled.span`
  display: block;

  &.hidden {
    display: none;
  }
`;

const StyledButton = styled.button`
  height: 45px;
  width: 100%;
  border-radius: 22.5px;
  background: linear-gradient(270deg, #f3dc30 0%, #ce5120 100%);
  color: #ffffff;
  font-family: "Adelle Sans";
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 45px;
  border-radius: 22.5px;
  background-color: #ffffff;
  margin-bottom: 10px;
  border: none;
  padding-left: 20px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  padding-top: 2px;
  font-family: "Adelle Sans";

  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #808080;
    font-family: "Adelle Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #808080;
    font-family: "Adelle Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  ::placeholder {
    color: #808080;
    font-family: "Adelle Sans";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  pointer-events: none;
  opacity: 0;
  z-index: 0;
  /* transition: opacity 0.355s cubic-bezier(0.8, 0, 0.07, 1), z-index .2s 1s; */
  transition: z-index 0.5s step-end, opacity 0.5s cubic-bezier(0.8, 0, 0.07, 1);

  @media (max-width: ${number_size.mobileL}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      135deg,
      rgba(42, 186, 217, 0.25) 0%,
      #8f20a1 100%
    );
    z-index: 20;
  }

  &.show {
    pointer-events: auto;
    transition: z-index 0.5s step-start,
      opacity 0.5s cubic-bezier(0.8, 0, 0.07, 1);
    z-index: 200;
    opacity: 1;
  }
`;

// const StyledVideo = styled.div`
//   position:absolute;
//   left:0;
//   top:0;
//   height:100vh;
//   width: 100vw;
// 	overflow:hidden;
//   z-index:-1;
//   object-fit: cover;
// 	z-index: 10;

// 	iframe{
// 		height: 56.25vw;
//     left: 50%;
//     min-height: 100%;
//     min-width: 100%;
//     transform: translate(-50%, -50%);
//     position: absolute;
//     top: 50%;
//     width: 177.77777778vh;
// 	}
// `

const StyledIcon = styled.div`
  width: 60%;
  margin-bottom: 105px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: "Adelle";
    color: #fff;
    font-size: 32px;
  }

  @media ${mediaMax.tablet} {
    width: 100%;
  }
`;

const MenuText = styled.p`
  color: #ffffff;
  font-family: "Adelle Sans";
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;

  opacity: 0;
  animation: 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.2s 1 normal forwards
    running ${fadeInUp};
`;

const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  width: 100%;
`;

interface FormContentProps {
  width?: string;
}
const FormContent = styled.form<FormContentProps>`
  opacity: 0;
  position: absolute;
  right: 0;
  width: ${({ width }) => width};
  top: 50px;
  z-index: -1;
  transition: opacity 0.3s ease-in;

  &.active {
    transition: opacity 0.3s ease-in 0.3s;
    opacity: 1;
    z-index: 2;
  }

  @media ${mediaMax.mobileL} {
    width: 100%;
  }
`;

const Content = styled.div`
  max-width: 1170px;
  width: 90vw;
  height: 80vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 30;

  @media ${mediaMax.tabletL} {
    margin-top: 100px;
  }

  @media (max-width: 500px) {
    height: 50vh;
  }
`;
