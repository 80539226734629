export const number_size = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 550,
  tablet: 768,
  tabletL: 1024,
  laptop: 1280,
  laptopL: 1440,
  desktop: 240,
}

export const size = {
  mobileS: `${number_size.mobileS}px`,
  mobileM: `${number_size.mobileM}px`,
  mobileL: `${number_size.mobileL}px`,
  tablet: `${number_size.tablet}px`,
  tabletL: `${number_size.tabletL}px`,
  laptop: `${number_size.laptop}px`,
  laptopL: `${number_size.laptopL}px`,
  desktop: `${number_size.desktop}px`,
}

export const mediaMin = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
}
export const mediaMax = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
}
